@import url(https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css);

h2 {
  font-weight: bold;
  font-size: 28px;
}

h3 {
  font-size: 22px;
  font-weight: bold;
}

textarea {
  width: 100%;
  resize: none;
}

// .pillar__wrap {
//   margin: 30px;
//   text-align: center;
// }

.sections__wrap {
  margin: 20px;
  display: flex;
  justify-content: space-around;
}

.section__wrap {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
}

.input__wrap {
  margin: 10px 0;
}

.submission-button__wrap {
  margin: 10px;
  display: flex;
  justify-content: center;
}

.react-tabs__tab-list {
  display: flex;
  justify-content: space-around;
  border-bottom: 2px solid black;
  margin: 30px;
  padding: 0;
}

.react-tabs__tab {
  border: solid black;
  border-width: 2px 2px 0px 2px;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
}

.header__wrap {
  text-align: center;

  h1 {
    font-weight: bold;
    font-size: 32px;
    margin-bottom: 15px;
  }
}

.login__wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.login-container {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 60%);
  padding: 10px 30px;
  border-radius: 10px;
  input {
    border-radius: 5px;
    border: 1px solid #aaa;
    box-shadow: none;
    display: block;
    font-size: 16px;
    font-weight: 300;
    height: 35px;
    line-height: 40px;
    padding: 0 0 0 10px;
    width: 100%;
  }
  button {
    background: #4050c6;
    border: 2px solid rgba(64, 80, 198, 0.5);
    color: #fff;
    border-radius: 2px;
    font-weight: 700;
    font-family: museo-sans, sans-serif;
    font-size: 12px;
    padding: 7px 15px;
    max-width: 140px;
    transition: all 0.5s;
    &:hover {
      background: #fff;
      border: 2px solid rgba(64, 80, 198, 0.5);
      color: #4050c6;
    }
  }
}

.item__wrap {
  margin: 25px;

  h2 {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 15px;
  }
}

.item-section__wrap {
  margin-bottom: 15px;
  
  h3 {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.item-input__wrap {
  flex-grow: 1;
  margin-bottom: 15px;
  margin-right: 15px;

  h4 {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
  }
}

.practice-section__wrap {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 15px;
  
  h3 {
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 10px;
  }
}

.value-input__input {
  width: 100%;
}

.item-input__input {
  margin-top: 23px;
}

// ALERT STYLES
.alert {
  left: 0;
  margin: auto;
  position: fixed;
  right: 0;
  width: 75%;
}
.alert .inner {
  display: block;
  padding: 6px;
  margin: 6px;
  border-radius: 3px;
  border: 1px solid rgb(180,180,180);
  background-color: rgb(212,212,212);
}

.alert .close {
  float: right;
  margin: 12px 12px 0px 0px;
  cursor: pointer;
}

.alert .inner,.alert .close {
  color: rgb(88,88,88);
}

.alert input {
  display: none;
}

// .alert input:checked ~ * {
//   animation-name: dismiss,hide;
//   animation-duration: 300ms;
//   animation-iteration-count: 1;
//   animation-timing-function: ease;
//   animation-fill-mode: forwards;
//   animation-delay: 0s,100ms;
// }

.alert.error .inner {
  border: 1px solid rgb(238,211,215);
  background-color: rgb(242,222,222);
}

.alert.error .inner,.alert.error .close {
  color: rgb(185,74,72);
}

.alert.success .inner {
  border: 1px solid rgb(214,233,198);
  background-color: rgb(223,240,216);
}

.alert.success .inner,.alert.success .close {
  color: rgb(70,136,71);
}

.alert.info .inner {
  border: 1px solid rgb(188,232,241);
  background-color: rgb(217,237,247);
}

.alert.info .inner,.alert.info .close {
  color: rgb(58,135,173);
}

.alert.warning .inner {
  border: 1px solid rgb(251,238,213);
  background-color: rgb(252,248,227);
}

.alert.warning .inner,.alert.warning .close {
  color: rgb(192,152,83);
}

// @keyframes dismiss {
//   0% {
//     opacity: 1;
//   }
//   90%, 100% {
//     opacity: 0;
//     font-size: 0.1px;
//     transform: scale(0);
//   }
// }

// @keyframes hide {
//   100% {
//     height: 0px;
//     width: 0px;
//     overflow: hidden;
//     margin: 0px;
//     padding: 0px;
//     border: 0px;
//   }
// }
// END ALERT STYLES